export default {
    "nav": {
        "main": "Главная",
        "remax": "RE/MAX",
        "technologies": "Технологии",
        "advantages": "Преимущества",
        "education": "Обучение",
        "offices": "Офисы",
    },
    "banner": {
        "h1": "Откройте агентство недвижимости с мировой франшизой",
        "btn": "Оставить заявку",
    },
    "about": {
        "title": "О КОМПАНИИ",
        "h1": "120 стран работают по франшизе",
        "p": "Запуск франшизы и использование передовых цифровых технологий сделало компанию мировым лидером среди агентств недвижимости.",
    },
    "goals": {
        "title": "МИССИЯ И ЦЕЛЬ",
        "h1": "RE/MAX Сегодня",
        "top_left": {
            "h1": "Наша цель",
            "p": "Быть лидером рынка недвижимости в Казахстане и лучшим выбором для брокеров, агентов и клиентов",
        },
        "top_center": {
            "h1": "RE/MAX входит в Топ-15 лучших франчайзи по всему миру",
            "popup": {
                "h1": "Топ-15 лучших франшиз по всему миру",
            },
        },
        "mid_right": {
            "h1": "Наша миссия",
            "p": "Повысить качество риэлторских услуг путем внедрения бизнес-модели RE/MAX.",
        },
        "bottom": {
            "p1": "лет опыта",
            "p2": "офисов",
            "p3": "стран мира",
            "p4": "брокеров",
        },
    },
    "technologies": {
        "title": "ТЕХНОЛОГИИ",
        "h1": "Технологии RE/MAX для быстрого старта и стремительного роста",
        "top_left": {
            "h1": "RE/MAX в Казахстане",
            "p": "Сайт с возможностью покупки, продажи, аренды жилой и коммерческой недвижимости в Казахстане и во всем мире.",
        },
        "right": {
            "h1": "CRM - система iConnect",
            "li1": "Основной инструмент работы агента и брокера.",
            "li2": "Разработана под нужды агентства недвижимости RE/MAX",
            "li3": "Ведение базы недвижимости, клиентов и контактов",
            "li4": "Автоматический подбор недвижимости для клиентов",
            "li5": "Онлайн-версия и мобильное приложение",
        },
        "bottom_left": {
            "h1": "MAX Center - международный ресурс с инструментами для эффективной работы агентов и брокеров:",
            "li1": "RE/MAX Университет",
            "li2": "Маркетинговый портал рекламных материалов",
            "li3": "Полезные инструменты и средства",
        },
    },
    "request": {
        "title": "ЗАЯВКА",
        "h1": "Станьте успешны вместе с одной из самых эффективных бизнес-моделей мира.",
        "p": "Оставьте свои контакты и получите расчет инвестиций на открытие вашего бизнеса.",
        "form": {
            "name": "Имя",
            "phone": "Телефон",
            "email": "Эл. почта",
            "submit": "Отправить",
            "confirm": "Нажимая кнопку 'Отправить', Вы подтверждаете свое согласие с нашей политикой конфиденциальности.",
        },
    },
    "advantages": {
        "block1": {
            "h1": "Лучшая в мире бизнес-модель",
            "p": "Ноу-хау и отработанная система помогает RE/MAX уже 50 лет сохранять лидерские позиции в более чем 119 странах мира.",
        },
        "block2": {
            "h1": "Постоянное обучение агентов и брокеров",
            "p": "Тренинги являются ключем к нашему успеху. Лучшие тренеры и успешные брокеры обучают владельцев офисов и наших агентов регулярно - лично и онлайн 24/7.",
        },
        "block3": {
            "h1": "Поддержка на каждом шагу",
            "p": "Профессиональная поддержка на этапе становления собственного бизнеса – основа для стабильного развития.",
        },
        "block4": {
            "h1": "Всемирно известный бренд",
            "p": "RE/MAX является одним из самых узнаваемых и уважаемых брендов в мире недвижимости.",
        },
        "block5": {
            "h1": "Привлечение лучших агентов",
            "p": "Эффективная система для привлечения и подготовки успешных брокеров.",
        },
        "block6": {
            "h1": "Сеть профессионалов",
            "p": "Сеть RE/MAX объединяет более 140 000 профессиональных агентов во всем мире.",
        },
    },
    "education": {
        "title": "ОБУЧЕНИЕ",
        "h1": "Обучение RE/MAX - это обмен опытом и синергия всей сети",
        "card1": {
            "h1": "Владелец франшизы",
            "li1": "Руководство по открытию и управлению офисом",
            "li2": "Рекрутинг и содержание агентов",
            "li3": "Инструменты RE/MAX",
            "li4": "Процедуры, отчетность, особенности сотрудничества",
        },
        "card2": {
            "h1": "Брокер по недвижимости",
            "li1": "Все аспекты ведения риэлторского бизнеса",
            "li2": "Групповое и индивидуальное обучение в офисе и online",
            "li3": "Методические материалы для использования",
            "li4": "Участие в конференциях RE/MAX и риелторов Казахстана",
        },
        "card3": {
            "h1": "Университет",
            "li1": "Ресурс для самостоятельного развития и роста профессионализма агентов и брокеров",
            "li2": "Доступ к курсам и программам обучения online",
            "li3": "1000 видео, охватывающих все аспекты рынка недвижимости",
        },
    },
    "offices": {
        "h1": "Города присутствия",
        "p": "Сеть RE/MAX расширяется в Казахстане.",
        "be_first": "Станьте первым в своем городе!",
        "soon": "Скоро",
    },
    "footer": {
        "company": "©2023, RE/MAX Казахстан. Все права защищены. Частная компания NEU Ltd.",
        "privacy_policy": "Политика конфиденциальности",
    },
    "notification": {
        "request": {
            "success": "Ваша заявка успешно отправлена",
            "error": "Произошла ошибка при отправке заявки",
        },
    },
};