export default {
    "nav": {
        "main": "Басты бет",
        "remax": "RE/MAX",
        "technologies": "Технологиялар",
        "advantages": "Артықшылықтары",
        "education": "Оқыту",
        "offices": "Офистар",
    },
    "banner": {
        "h1": "Әлемдік франчайзингпен жылжымайтын мүлік агенттігін ашыңыз",
        "btn": "Өтінім қалдыру",
    },
    "about": {
        "title": "Компания жайлы",
        "h1": "120 ел франшиза бойынша жұмыс істейді",
        "p": "Франчайзингтің іске қосылуы және озық цифрлық технологияларды қолдануы компанияны жылжымайтын мүлік агенттіктері арасында әлемдік көшбасшыға айналдырды",
    },
    "goals": {
        "title": "МИССИЯСЫ МЕН МАҚСАТЫ",
        "h1": "RE/MAX бүгін",
        "top_left": {
            "h1": "Біздің мақсатымыз",
            "p": "Қазақстандағы жылжымайтын мүлік нарығының көшбасшысы және брокерлер, агенттер мен клиенттер үшін ең жақсы таңдау болу",
        },
        "top_center": {
            "h1": "RE/MAX әлемдегі ең үздік франчайзилердің қатарына кіреді",
            "popup": {
                "h1": "Әлемдегі ең жақсы 15 Франчайзинг",
            },
        },
        "mid_right": {
            "h1": "Біздің миссиямыз",
            "p": "RE/MAX бизнес-моделін енгізу арқылы жылжымайтын мүлік қызметтерінің сапасын арттыру.",
        },
        "bottom": {
            "p1": "жылдық тәжірибе",
            "p2": "кеңселер",
            "p3": "әлем елдері",
            "p4": "брокерлер",
        },
    },
    "technologies": {
        "title": "Технологиялар",
        "h1": "Жылдам бастау және жылдам өсу үшін RE/MAX технологиялары",
        "top_left": {
            "h1": "Қазақстандағы RE/MAX",
            "p": "Қазақстанда және бүкіл әлемде тұрғын үй және коммерциялық жылжымайтын мүлікті сатып алу, сату, жалға алу мүмкіндігі бар Сайт.",
        },
        "right": {
            "h1": "IConnect CRM жүйесі",
            "li1": "Агент пен брокердің негізгі жұмыс құралы",
            "li2": "RE/MAX жылжымайтын мүлік агенттігінің қажеттіліктеріне арналған",
            "li3": "Жылжымайтын мүлік, клиенттер және байланыс базасын жүргізу",
            "li4": "Клиенттер үшін жылжымайтын мүлікті автоматты түрде таңдау",
            "li5": "Онлайн нұсқасы және мобильді қосымша",
        },
        "bottom_left": {
            "h1": "MAX Center-агенттер мен брокерлердің тиімді жұмыс істеуіне арналған құралдары бар халықаралық ресурс:",
            "li1": "RE/MAX университеті",
            "li2": "Жарнамалық материалдардың маркетингтік порталы",
            "li3": "Пайдалы құралдар мен құралдар",
        },
    },
    "request": {
        "title": "Өтінім",
        "h1": "Әлемдегі ең тиімді бизнес-модельдердің бірімен табысты болыңыз.",
        "p": "Контактілерді қалдырыңыз және бизнесіңізді бастау үшін инвестицияларды есептеңіз.",
        "form": {
            "name": "Есім",
            "phone": "Телефон",
            "email": "Эл. почта",
            "submit": "Жіберу",
            "confirm": "\"Жіберу\" батырмасын басу арқылы сіз біздің Құпиялылық саясатымызға келісіміңізді растайсыз.",
        },
    },
    "advantages": {
        "block1": {
            "h1": "Әлемдегі ең жақсы бизнес-модель",
            "p": "Ноу-хау және дамыған жүйе RE/MAX-қа 50 жылдан бері әлемнің 119-дан астам елінде көшбасшылық позициясын сақтауға көмектесіп келеді.",
        },
        "block2": {
            "h1": "Агенттер мен брокерлерді үздіксіз оқыту",
            "p": "Тренингтер біздің жетістігіміздің кілті болып табылады. Үздік жаттықтырушылар мен табысты брокерлер кеңсе иелері мен біздің агенттерді үнемі - жеке және онлайн режимінде оқытады 24/7.",
        },
        "block3": {
            "h1": "Әр қадамда қолдау",
            "p": "Өз бизнесінің қалыптасу кезеңіндегі кәсіби қолдау-тұрақты дамудың негізі.",
        },
        "block4": {
            "h1": "Әлемге әйгілі бренд",
            "p": "RE/MAX-жылжымайтын мүлік әлеміндегі ең танымал және құрметті брендтердің бірі.",
        },
        "block5": {
            "h1": "Үздік агенттерді тарту",
            "p": "Табысты брокерлерді тарту және дайындау үшін тиімді жүйе.",
        },
        "block6": {
            "h1": "Кәсіби мамандар желісі",
            "p": "RE/MAX желісі бүкіл әлем бойынша 140 000-нан астам кәсіби агенттерді біріктіреді.",
        },
    },
    "education": {
        "title": "Оқыту",
        "h1": "RE/MAX оқыту-бұл тәжірибе алмасу және бүкіл желінің синергиясы",
        "card1": {
            "h1": "Франшиза иесі",
            "li1": "Кеңсені ашу және басқару жөніндегі нұсқаулық",
            "li2": "Агенттерді жалдау және ұстау",
            "li3": "RE/MAX құралдары",
            "li4": "Рәсімдер, есептілік, ынтымақтастық ерекшеліктері",
        },
        "card2": {
            "h1": "Жылжымайтын мүлік брокері",
            "li1": "Жылжымайтын мүлік бизнесін жүргізудің барлық аспектілері",
            "li2": "Кеңседе жәнеineline-да топтық және жеке оқыту",
            "li3": "Қолдануға арналған әдістемелік материалдар",
            "li4": "RE/MAX және Қазақстан риэлторларының конференцияларына қатысу",
        },
        "card3": {
            "h1": "Университет",
            "li1": "Агенттер мен брокерлердің кәсібилігін дербес дамыту және өсіру үшін Ресурс",
            "li2": "Онлайн оқыту курстары мен бағдарламаларына қол жеткізу",
            "li3": "Жылжымайтын мүлік нарығының барлық аспектілерін қамтитын 1000 бейне",
        },
    },
    "offices": {
        "h1": "Келесі қалаларда бар",
        "p": "RE/MAX желісі Қазақстанда кеңеюде.",
        "be_first": "Өз қалаңызда бірінші болыңыз!",
        "soon": "Жақында",
    },
    "footer": {
        "company": "© 2023, RE/MAX Қазақстан. Барлық құқықтар қорғалған. Жеке компания NEU Ltd.",
        "privacy_policy": "Құпиялылыaқ саясаты",
    },
    "notification": {
        "request": {
            "success": "Өтініміңіз сәтті жіберілді",
            "error": "Өтінімді жіберу кезінде қате орын алды",
        },
    },
};