import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { CONTENTS } from "../../constants/app";
import useAdaptive from "../../hooks/useAdaptive";
import {
    Block,
    FlexBox,
    Title,
    Text,
    Container,
    Input,
    Button,
    Checkbox,
} from "../../components";
import useTranslate from "../../i18n/useTranslate";

import "./styles.scss";
import classNames from "classnames";

const OPERATORS_CODS = [700, 701, 702, 703, 704, 705, 706, 707, 708,  747, 750, 751, 760, 761, 771, 775, 776, 777, 778];

const Request = ({ modal = false, close, isShown = false }) => {
    const { request, notification } = useTranslate();
    const isMobile = useAdaptive();
    const [accepted, setAccepted] = useState(true);
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [status, setStatus] = useState(null);

    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source") ?? "/";
    const utmMedium = urlParams.get("utm_medium") ?? "/";
    const utmCampaign = urlParams.get("utm_campaign") ?? "/";
    const utmContent = urlParams.get("utm_content") ?? "/";
    const utmTerm = urlParams.get("utm_term") ?? "/";
    const utmPlatform = "/";
    const fromSite = "rams.franchise.com.kz";
    const fbclid = urlParams.get("fbclid") ?? "/";
    const yclid = urlParams.get("yclid") ?? "/";
    const gclid = urlParams.get("gclid") ?? "/";

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("email", email ?? "");
        formData.append("gclid", gclid);
        formData.append("yclid", yclid);
        formData.append("fbclid", fbclid);
        formData.append("utm_source", utmSource);
        formData.append("utm_medium", utmMedium);
        formData.append("utm_campaign", utmCampaign);
        formData.append("utm_content", utmContent);
        formData.append("utm_term", utmTerm);
        formData.append("utm_platform", utmPlatform);
        formData.append("fullurl", window.location.href);
        formData.append("from_site", fromSite);

        try {
            setStatus("fetching");
            await fetch("send.php", {
                method: "POST",
                body: formData,
            });
            setStatus("success");
            setEmail("");
            setName("");
            setPhone("");
        } catch (e) {
            setStatus("error");
        } finally {
            setTimeout(() => {
                setStatus(null);
            }, 6000);
        }
    };

    const getStatusIndicator = (status) => {
        switch (status) {
            case "success":
                return (
                    <Text color="white" size={15}>
                        {notification.request.success}
                    </Text>
                );
            case "error":
                return (
                    <Text color="white" size={15}>
                        {notification.request.error}
                    </Text>
                );
            default:
                return null;
        }
    };

    const handleChangePhone = (newPhone) => {
        const onlyNumber = newPhone?.replace(/\D+/g, '');
        const onlyClientInput = onlyNumber.slice(1, onlyNumber.length);
        
        if (onlyClientInput.length > 10) {
            return;
        }

        let result = "";

        if (onlyClientInput.length <= 10) {
            result = `+7 (${onlyClientInput.slice(0, 3)}) ${onlyClientInput.slice(3, 6)}-${onlyClientInput.slice(6, 8)}-${onlyClientInput.slice(8, 10)}`;
        }

        if (onlyClientInput.length <= 8) {
            result = `+7 (${onlyClientInput.slice(0, 3)}) ${onlyClientInput.slice(3, 6)}-${onlyClientInput.slice(6, 8)}`;
        }

        if (onlyClientInput.length <= 6) {
            result = `+7 (${onlyClientInput.slice(0, 3)}) ${onlyClientInput.slice(3, 6)}`;
        }

        if (onlyClientInput.length <= 3) {
            result = `+7 (${onlyClientInput}`;
        }

        setPhone(result);
    };



    return (
        <>
            <div className="notification">
                <div
                    className={`status-container ${
                        status ? status : "success"
                    }`}
                    style={{
                        display: status === "success" ? "block" : "none",
                    }}
                    id="notification-success"
                >
                    {getStatusIndicator(status ? status : "success")}
                </div>
            </div>

            <div
                id={CONTENTS.REQUEST}
                className={classNames("request-form", { modal, "opened": isShown })}
                style={{ scrollMarginTop: 80 }}
                onClick={modal ? close : undefined}
            >
                <Container m={isMobile ? "0 5px" : undefined}>
                    <Block color="red-500" p={isMobile ? 30 : 70} radius={12} onClick={(e) => e.stopPropagation()}>
                        {modal
                            ? (
                                <button className="close-modal" onClick={close}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M8.46445 15.5359L15.5355 8.46484" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M8.46446 8.46409L15.5355 15.5352" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            )
                            : null
                        }
                        <FlexBox
                            gap={isMobile ? 30 : 80}
                            direction={isMobile ? "column" : "row"}
                        >
                            <FlexBox
                                direction="column"
                                width={isMobile ? "100%" : "60%"}
                                gap={20}
                            >
                                <Title iconColor="blue-500" textColor="white">
                                    {request.title}
                                </Title>
                                <Text
                                    withAnimation
                                    as={isMobile ? "h2" : "h1"}
                                    color="white"
                                >
                                    {request.h1}
                                </Text>
                                <Text color="white">
                                    {request.p}
                                </Text>
                            </FlexBox>
                            <form
                                style={{ width: isMobile ? "100%" : "40%" }}
                                onSubmit={handleSubmit}
                            >
                                <Input
                                    placeholder={request.form.name}
                                    required
                                    minLength={2}
                                    maxLength={25}
                                    value={name}
                                    onChange={setName}
                                />
                                <Input
                                    placeholder={request.form.phone}
                                    required
                                    type="phone"
                                    pattern={String.raw`^\+7\s\((${OPERATORS_CODS.join("|")})\)\s\d{3}-\d{2}-\d{2}$`}
                                    value={phone}
                                    onChange={handleChangePhone}
                                    onFocus={() => setPhone((prev) => prev || '+7 (')}
                                    onBlur={() => setPhone((prev) => prev === '+7 (' ? "" : prev)}
                                />
                                <Input
                                    placeholder={request.form.email}
                                    maxLength={50}
                                    type="email"
                                    value={email}
                                    onChange={setEmail}
                                />
                                <FlexBox
                                    gap={20}
                                    justify="space-between"
                                    align={isMobile ? "flex-start" : "center"}
                                    direction={isMobile ? "column" : "row"}
                                >
                                    <Button
                                        variant="primary"
                                        color="blue"
                                        type="submit"
                                        disabled={
                                            !accepted || (status === "fetching")
                                        }
                                    >
                                        {request.form.submit}
                                    </Button>
                                    <Checkbox
                                        onChange={setAccepted}
                                        value={accepted}
                                        label={(
                                            <span>
                                                <Link to="privacy-policy" className="link">{request.form.confirm}</Link>
                                            </span>
                                        )}
                                    />
                                </FlexBox>
                            </form>
                        </FlexBox>
                    </Block>
                </Container>
            </div>
        </>
    );
};

export default Request;
