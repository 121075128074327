import React from "react";

const Remax = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="19" viewBox="0 0 100 19" fill="none">
        <path d="M6.81304 9.33969H3.99546V4.23579H6.78989C6.78989 4.23579 9.60747 4.25882 9.60747 6.61447C9.60747 8.97008 7.85228 9.33969 6.81304 9.33969ZM10.2541 11.9263C10.2541 11.9263 13.6029 10.7254 13.6029 6.84544C13.6029 5.02092 13.2334 3.75072 12.0554 2.57295C10.7852 1.30275 8.63752 0.840942 7.71363 0.840942H0V18.4852H3.99546V12.7346H6.21258L9.63046 18.4852H14.1804L10.2541 11.9263Z" fill="#DC1C2E"/>
        <path d="M17.8291 0.840942H29.769V4.21264H21.7321V7.88483H28.8221V11.2565H21.7551V15.021H27.0438L25.2426 18.4852H17.8291V0.840942Z" fill="#DC1C2E"/>
        <path d="M29.2842 18.4852L38.5682 0.840942H43.3949L34.0876 18.4852H29.2842Z" fill="#003DA5"/>
        <path d="M43.395 18.4852H47.2979V7.53836L51.4781 13.0119H51.6398L55.8431 7.53836V18.4852H59.769V0.840942H55.589L51.5706 6.5453L47.5518 0.840942L43.395 8.5776V18.4852Z" fill="#DC1C2E"/>
        <path d="M69.2608 11.3028L71.2239 5.94481L73.0483 11.3028H69.2608ZM73.1638 0.841064H69.3302L62.7251 18.4853H66.7206L68.0831 14.6747H74.2492L75.6581 18.4853H79.7458L73.1638 0.841064Z" fill="#DC1C2E"/>
        <path d="M81.4553 0.840942L86.6745 9.50133L81.2012 18.4852H85.6353L88.9611 12.55L92.2404 18.4852H96.767L91.2473 9.43205L96.5591 0.840942H92.1019L89.0303 6.38363L85.9586 0.840942H81.4553Z" fill="#DC1C2E"/>
        <path d="M98.6603 2.57301C98.0829 2.57301 97.6211 2.11105 97.6211 1.53378C97.6211 0.95635 98.0829 0.49454 98.6603 0.49454C99.2376 0.49454 99.6996 0.95635 99.6996 1.53378C99.7226 2.08806 99.2376 2.57301 98.6603 2.57301ZM98.6603 0.194214C97.9214 0.194214 97.3208 0.794671 97.3208 1.53378C97.3208 2.27273 97.9214 2.87318 98.6603 2.87318C99.3994 2.87318 99.9998 2.27273 99.9998 1.53378C99.9998 0.794671 99.3994 0.194214 98.6603 0.194214Z" fill="#DC1C2E"/>
        <path d="M98.6375 1.44131C98.7067 1.44131 98.7528 1.41816 98.799 1.39513C98.8453 1.37198 98.8453 1.30282 98.8453 1.23333C98.8453 1.09484 98.776 1.02552 98.6375 1.02552H98.4989V1.44131H98.6375ZM98.7528 0.794556C98.8685 0.794556 98.9608 0.81786 99.0299 0.887028C99.0993 0.956352 99.1224 1.04867 99.1224 1.16417C99.1224 1.25652 99.0993 1.34899 99.0531 1.39513C99.0068 1.46446 98.9608 1.48748 98.8685 1.51063C98.9376 1.51063 98.9838 1.53378 99.0301 1.57996C99.0531 1.62613 99.0763 1.67227 99.0993 1.76474V1.8571V1.97244C99.0993 2.04188 99.0993 2.11109 99.1224 2.15727C99.1224 2.2034 99.1454 2.22655 99.1686 2.24974V2.27273H98.8453C98.8223 2.24974 98.8223 2.22655 98.8223 2.2034V2.11109V1.83395C98.8223 1.78777 98.799 1.74148 98.776 1.6953C98.7528 1.67227 98.7067 1.64912 98.6375 1.64912H98.4757V2.24974H98.1755V0.794556H98.7528Z" fill="#DC1C2E"/>
    </svg>
);

export default Remax;